import React from 'react';
import {HashRouter, Route, Routes} from "react-router-dom";
import Navbar from './misc/Navbar';
import Landing from './pages/Landing';
import About from './pages/About';
import MentalToughness from './pages/MentalToughness';
import Books from './pages/Books';
import Resources from './pages/Resources';
import Blog from './pages/Blog';
import Shop from './pages/Shop';
import Footer from './misc/Footer';
import "./Router.css";
import OptimiseLearning from './pages/OptimiseLearning';

export default function Router() {
    return (
        <HashRouter>
            <Navbar />
            <div className="contentRoute">
                <Routes>
                    <Route exact path="/" element={<Landing/>}></Route>
                    <Route path="about" element={<About />}></Route>
                    <Route path="mental-toughness" element={<MentalToughness />}></Route>
                    <Route path="books" element={<Books />}></Route>
                    <Route path="resources" element={<Resources />}></Route>
                    <Route path="optimise-learning" element={<OptimiseLearning />}></Route>
                    <Route path="blog" element={<Blog />}></Route>
                    <Route path="shop" element={<Shop />}></Route>
                </Routes>
                </div>
                <div className="footerRoute">
            <Footer/>
            </div>
        </HashRouter>
    )
}
