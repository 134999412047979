import React from 'react';
import "./OptimiseLearning.css";
import beaverDrawing from "../images/beaverDrawing.jpg";
import MTPizza from "../images/MTPizza.png";


export default function OptimiseLearning() {
    return (
        <div className="optimiseLearning">
            <h1>Optimise Learning</h1>
            <h2 style={{color: "#ed364c"}}>The Broad Approach</h2>
<br></br>
            <p style={{fontWeight: "bold"}}>The general approach in encouraging discussion with a young child is to:</p>
            <img src={beaverDrawing} id="beaverDrawing" alt="Beaver Drawing" width="400px"></img>
<br></br>
            <ul>
                <li>Discuss what they think each verse is about. What do they think is happening to the central character?</li>
                <li>How else could the character have responded? Would that have been better… or worse?</li>
                <li>If other characters are involved, what are they doing? Are they hindering? If so, what should they do? If they are helping, how useful is that and why?</li>
                <li>What is it that ultimately helps the central character to succeed?</li>
                <li>At each verse or interval, ask “Does anything like this ever happen to you or your friends?”. This provides an opportunity to relate the theme to their lives.</li>
                <li>Finally, discuss what the point of the story is and how can they adopt the lesson in their lives and the situations they, or perhaps their friends face.</li>
            </ul>

            <h2 style={{color: "#7030A0"}}>Understanding Self</h2>
<br></br>
            <p>When working with others to develop their mental toughness, it is more often than not useful to be self-aware about our own mental toughness. Our mental approach to others can be a factor in the way we engage with them.</p>
            <br></br>
            <p>For instance, if we have a high level of life control, i.e. a strong sense that nothing is impossible, it can be difficult for us to understand and to engage with others who are more hesitant and cautious. And perhaps we once were too?</p>
            <br></br>
            <p>This is true for all 8 factors.</p>
            <br></br>
            <p>One option is to complete the <span style={{color: "#007b60"}}>MTQ</span><span style={{color: "#ed364c"}}>Plus</span> which is the most advanced of the Mental Toughness questionnaires and is recognised as a high-quality psychometric measure.</p>
            <br></br>
            <p>This comes with a useful set of reports which are easy to read. </p>
            <br></br>
            <p>This can be accessed via <a href="https://aqrinternational.co.uk/product/mtqplus-assessment" target="_blank" rel="noreferrer">aqrinternational.co.uk/product/mtqplus-assessment</a></p>
            <br></br>
            <p>Where thought useful, this can be supported by coaching or feedback from qualified and experienced coaches and educators. If interested, go to Contact Us and we’ll respond to identify what your needs might be.</p>

            <h2 style={{color: "#4f81bd"}}>How can Mental Toughness be developed?</h2>
            <img src={MTPizza} id="MTPizza" alt="Mental Toughness Subscales" width="400px"></img>
<br></br>
            <p>Almost all the exercises that develop Mental Toughness, Resilience and a Positive Attitude in children are experiential.  Children learn by doing and handling feedback and recognition is very important for several reasons.</p>
            <br></br>
            <p>One is that development and change is often slow and day to day changes are slight. Children will often not recognise that they are doing anything significantly different. If so, they can become discouraged and think they have failed.</p>
            <br></br>
            <p>It is important that adults recognise even the smallest of differences, and acknowledge them. Children will often like it that someone has noticed a change, however small.</p>
            <br></br>
            <p>Professor Carol Dweck drew attention to another aspect of recognition. Firstly, she noted that for children, recognition was indeed important. She also noted that adults often praised a child in one of two ways.</p>
            <br></br>
            <p>Sometimes (more often than not) for being clever enough to do something different or better. Sometimes the praise recognised the effort the child had made in doing something. Dweck found that recognising effort was generally more effective.</p>
            <br></br>
            <p>If a child got used to being praised for being clever enough to achieve something, when they failed, they could assume they weren’t clever enough. And that they couldn’t do anything about that.</p>
            <br></br>
            <p>If a child got used to being praised for the effort they had made when achieving things, when they struggled or failed at something, they would assume it was because they hadn’t made enough effort. And that was something they could do something about. They didn’t assume they weren’t clever enough.</p>
            <br></br>
            <p>As in many things, praise has to be balanced between the two types. There should be an emphasis on effort to instill the idea that making an effort brings its own rewards.</p>
            <br></br>
            <p>It is also important not to “over praise” and give recognition for every small achievement. Children soon recognise too much praise and recognition and it loses its value.</p>

        </div>
    )
}
