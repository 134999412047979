import React from 'react';
import "./Resources.css";
import beaverCoverArt from "../images/beaverCoverArt.png";
import reflectiveQuestions from "../resources/little-beaver/beaver-reflective-questions.pdf";
import beaverPlansColouring from "../resources/little-beaver/beaver-plans-colouring.pdf";
import beaverToolsColouring from "../resources/little-beaver/beaver-tools-colouring.pdf";
import beaversFriends from "../resources/little-beaver/bird-mouse-colouring.pdf";
import rainyDays from "../resources/little-beaver/rainy-day-activities.pdf"

import MailchimpForm from '../misc/mailchimp/Mailchimp';

import hedgehogCoverArt from "../images/hedgehogCoverArt.png";
import hedgehogQuestions from "../resources/hedgehog/hedgehogQuestions.pdf";
import elephantColouring from "../resources/hedgehog/elephant.pdf";
import fruitVegColouring from "../resources/hedgehog/fruitVeg.pdf";
import toyboxColouring from "../resources/hedgehog/toybox.pdf";
import undergrowthColouring from "../resources/hedgehog/undergrowth.pdf";

import pelicanCoverArt from "../images/pelicanCoverArt.png";
import pelicanQuestions from "../resources/pelican/pelicanQuestions.pdf";

import ukraineCoverArt from "../images/ukraineCoverArt.png";
import ukraineAnimals from "../resources/ukraine/animals.pdf";
import ukraineAnthem from "../resources/ukraine/anthem.pdf";
import ukraineHints from "../resources/ukraine/hints.pdf";
import ukraineIllustrators from "../resources/ukraine/illustrators.pdf";

import parentingCoverArt from "../images/parentingCoverArt.png";
import parentingThreePositives from "../resources/parenting/ThinkThreePositives.pdf";
import kindnessBingoOne from "../resources/parenting/RandomActsOfKindnessBingoOne.pdf";
import kindnessBingoTwo from "../resources/parenting/RandomActsOfKindnessBingoTwo.pdf";
import rainbowCheckIn from "../resources/parenting/RainbowCheckIn.pdf";
import breathingStar from "../resources/parenting/MindfulnessBreathingStar.pdf";
import breathingRainbow from "../resources/parenting/MindfulnessBreathingRainbow.pdf";


const littleBeaverExercises =
<div id="resourcesList">
<div>
    <img src={parentingCoverArt} id="beaverCoverArt" alt="A Thriving Mind Cover Art"></img>
    <h2 style={{ marginTop: "1em" }}>A Thriving Mind</h2>
    <div id="exerciseList">
        <h3>Exercises</h3>
        <a href={parentingThreePositives} target="_blank" rel="noreferrer">Think Three Positives</a>
        <a href={kindnessBingoOne} target="_blank" rel="noreferrer">Random Acts of Kindness Bingo 1</a>
        <a href={kindnessBingoTwo} target="_blank" rel="noreferrer">Random Acts of Kindness Bingo 2</a>
        <a href={rainbowCheckIn} target="_blank" rel="noreferrer">Rainbow Check-In</a>
        <a href={breathingStar} target="_blank" rel="noreferrer">Mindfulness Breathing - Star</a>
        <a href={breathingRainbow} target="_blank" rel="noreferrer">Mindfulness Breathing - Rainbow</a>
        </div>
    </div>
<div>
    <img src={beaverCoverArt} id="beaverCoverArt" alt="Little Beaver, Big Achiever Cover Art"></img>
    <h2 style={{ marginTop: "1em" }}>Little Beaver, Big Achiever</h2>
    <div id="exerciseList">
        <h3>Exercises</h3>
        <a href={reflectiveQuestions} target="_blank" rel="noreferrer">Reflective Questions</a>
        <a href={beaverPlansColouring} target="_blank" rel="noreferrer">Beaver Making Plans - Colouring in Page</a>
        <a href={beaverToolsColouring} target="_blank" rel="noreferrer">Beaver With Tools - Colouring in Page</a>
        <a href={beaversFriends} target="_blank" rel="noreferrer">Beavers Friends - Colouring in Page</a>
        <a href={rainyDays} target="_blank" rel="noreferrer">Activities for Rainy Days</a>
        </div>
    </div>

    <div>
    <img src={hedgehogCoverArt} id="beaverCoverArt" alt="How Hedgehog Found His Smile Cover Art"></img>
    <h2 style={{ marginTop: "1em" }}>How Hedgehog Found His Smile</h2>
    <div id="exerciseList">
        <h3>Exercises</h3>
        <a href={hedgehogQuestions} target="_blank" rel="noreferrer">Reflective Questions</a>
        <a href={elephantColouring} target="_blank" rel="noreferrer">Elephant - Colouring in Page</a>
        <a href={fruitVegColouring} target="_blank" rel="noreferrer">Fruit and Veg - Colouring in Page</a>
        <a href={toyboxColouring} target="_blank" rel="noreferrer">Toybox - Colouring in Page</a>
        <a href={undergrowthColouring} target="_blank" rel="noreferrer">Undergrowth - Colouring in Page</a>
        </div>
    </div>

    <div>
    <img src={ukraineCoverArt} id="beaverCoverArt" alt="The Nightingale and the Bear Cover Art"></img>
    <h2 style={{ marginTop: "1em" }}>How Hedgehog Found His Smile</h2>
    <div id="exerciseList">
        <h3>Exercises</h3>
        <a href={ukraineAnimals} target="_blank" rel="noreferrer">National Animals</a>
        <a href={ukraineAnthem} target="_blank" rel="noreferrer">Ukraine National Anthem</a>
        <a href={ukraineIllustrators} target="_blank" rel="noreferrer">Our Illustrators</a>
        <a href={ukraineHints} target="_blank" rel="noreferrer">Additional Hints and Suggestions</a>
        </div>
    </div>

    <div>
    <img src={pelicanCoverArt} id="beaverCoverArt" alt="How the Pelican Got its Name Cover Art"></img>
    <h2 style={{ marginTop: "1em" }}>How The Pelican Got Its Name</h2>
    <div id="exerciseList">
        <h3>Exercises</h3>
        <a href={pelicanQuestions} target="_blank" rel="noreferrer">Reflective Questions</a>
        </div>
    </div>

    </div>


export default function Resources() {
    return (
        <div className="resources">
            <h1>Resources</h1>

            <div>
                <MailchimpForm resources={littleBeaverExercises} />
            </div>

        </div>
    )
}