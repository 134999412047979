import React from 'react';
import "./About.css";
import MMS from "../images/agents/mms.png";
import bookSource from "../images/agents/book-source.png";

export default function About() {
    return (
        <div className="about">
            <h1>About Us</h1>
            <p>Resilient Hedgehog is a division of AQR International. AQR is an organisation which is recognised as a thought leader on the Mental Toughness concept. Mental Toughness is increasingly recognised as one of the most important qualities about which a person can develop self-awareness. It embraces ideas such as mindset, resilience, optimism, positivity, grit and character.</p>
            <br></br>
            <p> Vitally important for adults, these are the topics that parents, guardians and teachers often have at the top of their agenda when working with young children.</p>
            <br></br>
            <p>The Resilient Hedgehog concept and website is intended as a highly interactive resource for parents and for teachers who work with young children broadly between the ages of 3 and 12.</p>

            <h2 style={{color: "#7030A0"}}>Our Mission</h2>
            <p>Research in 2021 found that when a 6 year old enjoyed learning, this continued well into adolescence and led to better attainment (Tim T. Morris, 2021). Our mission is to enable that journey to be achieved by supporting all those involved in raising and developing children to learn core life skills and to enjoy the process of doing so.</p>

            <h2 style={{color: "#007b60"}}>AQR International</h2>
            <p>AQR International is the parent company for Resilient Hedgehog publishing. It is an organisation which develops concepts and materials that help individuals and organisation be the best version of themselves that they can be.</p>
            <br></br>
            <p>It is recognised as a global though leader for the Mental Toughness concept. With a provenance going back to Plato who described it as Fortitude, one of the 4 virtues, the concept has always been known to be vitally important but until recently has been difficult to explain and to apply.</p>
            <br></br>
            <p>A key aspect of AQR’s work is that it is strongly evidence-based. There are now more than 250 serious academic research papers which support the concept and its application in every sphere, including the development of young people, as well as the mental toughness psychometric measures.</p>
            <br></br>
            <p>AQR International has now turned its attention to bringing this hugely important concept into the development of young children.</p>
            <br></br>
            <p>`You can learn more about AQR International at <a href="https://aqrinternational.co.uk/" target="_blank" rel="noreferrer">www.aqrinternational.co.uk</a>. The AQR team consists of practitioners with experience in every sector imaginable as well as academic colleagues and partners who ensure that our approaches are well evidenced, rigorous and thorough.`</p>

            <h2 style={{color: "#4F81BD"}}>Authors and Illustrators</h2>
            <h4>Dorien van ‘t Ende - Author</h4>
            <p>Dorien van ‘t Ende is the mother of Niamh Lotus. She lives with her partner Jonathan and dog Tobie in Chester, England. With a masters degree and originally from the Netherlands, Dorien’s keen interest is in early years development and helping parents to provide the best upbringing they can manage.</p>
            <br></br>
            <h4>Helen Robinson - Illustrator</h4>
            <p>Helen Robinson, M.A. is originally from Yorkshire now living on the north coast of Wales. Helen gained her BA (hons) Illustration for Children's Publishing and Masters in Design Practice from Glyndwr University. Helen's passion is to enable others to become the best they can be. <a href="https://theaoi.com/folios/helenrobinsonjaminoshdesigns/" target="_blank" rel="noreferrer">View Portfolio</a></p>

            <h2 style={{color: "#ed364c"}}>Publishing Team</h2>
            <h4>Doug Strycharczk - CEO</h4>
            <p>Doug has more than 30 years expertise in a variety of Line, HR and Consultancy roles with a number businesses. In recent years, Doug has worked with Professor Peter Clough to define mental toughness and to create the world’s leading measure of mental toughness.</p>
            <br></br>
            <h4>Lynn Morris - Copy Editor</h4>
            <p>Lynn Morris has a BA in Visual Arts from Lancaster University. She lives in Chester with partner Paul and dog, Nelly. Lynn is the Marketing and Community Liaison Officer for Queens Park High School.</p>
            <br></br>
            <h4>Claudine Rowlands - Production Manager</h4>
            <p>Claudine brings experience in a number of areas including finance, customer service, marketing and product management having held roles across a variety of sectors from printing and production through to the leisure industry.</p>
            <br></br>
            <h4>Toni Molyneux - Content Development</h4>
            <p>Toni holds a British Psychological Society accredited degree in Psychology from the University of Chester and a Masters Degree in Organisational Psychology from the University of Manchester.</p>
            <br></br>
            <h4>Peter Clough - Technical Consultant</h4>
            <p>Peter is known the world over as a leading authority on Mental Toughness. He is the originator of the 4Cs concept which is now, be a long way, the most widely adopted description that is emerging as a fundamentally important concept in understanding and developing individual and organisations.</p>
            <br></br>
            <h4>John Perry - Technical Consultant</h4>
            <p>For a number of years John has been a core member of our external academic team which has continued the development of the mental toughness concept to its 8 factor conceptualization. John is an excellent presenter and is very much in demand as a speaker on mental toughness and related topics.</p>

            <h2>Enquires and Sales</h2>
            <h4>For trade, export and educational sales enquiries please contact MMS</h4>
            <p>Email: info@mms-publishing.com</p>
            <p>Tel: +44 (0) 20 8898 5211</p>
            <h4>For all rights enquiries please contact MMS</h4>
            <p>Email: petula@mms-publishing.com</p>
            <h4>For all trade, export and educational orders please contact BookSource</h4>
            <p>Email: orders@booksource.net</p>
            <img src={MMS} alt="MMS Logo"></img>
            <img src={bookSource} alt="MMS Logo"></img>

<p style={{fontWeight:"italic"}} > Tim. T. Morris, et al., (2021) Associations between school enjoyment at age 6 and later educational achievement: evidence from a UK cohort study (<a href="https://doi.org/10.1038/s41539-021-00092-w" target="_blank" rel="noreferrer">Online</a>)</p> 
        </div>
    )
}
