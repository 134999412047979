import React from 'react'
import {Link} from "react-router-dom";
import "./Landing.css";
import mentalToughnessPizza from "../images/mentalToughnessPizza.png"
import resilientHedgehogLogo from "../images/resilientHedgehog.png"
import miceReading from "../images/twoMice.png"
import shop from "../images/shop.png";
import background from "../images/background.png";
import hedgehogReading from "../images/hedgehogReading.png"
import Slideshow from "../misc/Slideshow"

export default function Landing() {
    return (
        <div className="landing">
            
            <div className="browseBooks">
                <div>
                <h2>Browse Our Books..</h2>
                    <h3>Resilient Creatures</h3>
                    <Link to="/books" style={{textDecoration: "none"}}><h4>Little Beaver, Big Achiever</h4></Link>
                    <Link to="/books" style={{textDecoration: "none"}}><h4>How Hedgehog Found His Smile</h4></Link>
                    <Link to="/books" style={{textDecoration: "none"}}><h4>How the Pelican Got its Name</h4></Link>
                    <Link to="/books" style={{textDecoration: "none"}}><h4>The Fastest Sloth in the World</h4></Link>
                    </div>
                <div className="testimonials">
            <Slideshow/>
            </div>

            </div>

            <div className="landingMain">
            <h1>Welcome to Resilient Hedgehog</h1>
            <p id="strapLine">A unique innovative resource for parents and teachers. Approaches and materials for those engaged in developing young children.</p>
            <div className="landingIcons">
            <Link className="landingIcon" to="/about"><img src={resilientHedgehogLogo} alt="Logo"></img><h2 style={{color: "#007b60"}}>About Us</h2></Link>
            <Link className="landingIcon" to="/optimise-learning"><img src={hedgehogReading} alt="Mice Reading Book"></img><h2 style={{color: "#7030a0"}}>Optimise Learning</h2></Link>
            <Link className="landingIcon" to="/mental-toughness"><img src={mentalToughnessPizza} alt="Mental Toughness"></img><h2 style={{color: "#4f81bd"}}>Mental Toughness</h2></Link>
            <Link className="landingIcon" to="/resources"><img src={miceReading} alt="Mental Toughness"></img><h2 style={{color: "#f2994a"}}>Resources</h2></Link>
            <Link className="landingIcon" to="/shop"><img src={shop} alt="Shop"></img><h2 style={{color: "#ed364c"}}>Shop</h2></Link>
            </div>
            <img id="backgroundImage" src={background} alt="Hedgehog and Friends"></img>
            </div>
        </div>
    )
}
