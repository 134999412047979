import React from 'react';
import "./Blog.css";

export default function Blog() {
    return (
        <div className="blog">
<div id="fb-root"></div>

            <h1>Blog</h1>
            <p>The latest updates and resources from Resilient Hedgehog</p>

            <iframe title="facebookFeed" id="facebookPosts" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FResilientHedgehog%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
    )
}
