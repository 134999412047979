import React from 'react';
import "./MentalToughness.css";
import birdMouseQuestion from "../images/birdMouseQuestion.jpg"

export default function MentalToughness() {
    return (
        <div className="mentalToughness">
            <h1>Mental Toughness</h1>
            <article>
                <h2 style={{ color: "#ed364c" }}>What Mental Toughness is..</h2>
                <p>All Resilient Hedgehog books use the Mental Toughness framework. This is a very well evidenced psychological concept which describes much of what is often described as mindset or attitude.</p>
                <br></br>
                <p>Based on the pioneering work of Professor Peter Clough who first identified that there are 4 main elements – Control, Commitment, Challenge and Confidence. It has since been developed so that we now understand there are 8 independent components under these 4 elements. Each is important and each is capable of being developed.</p>
                <br></br>
                <p>In turn this has been operationalised by AQR International which is recognised for thought leadership for this important concept. This concept is widely used to develop people of all ages.</p>

                <table>
                    <tr>
                        <th rowSpan="2" style={{ backgroundColor: "#007b60" }}>Control</th>
                        <td style={{ backgroundColor: "#4ca38f" }}>Life Control - Where the sense of my self worth and “can do” sits.  I think I have enough control of life and circumstances to attempt most things.</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#4ca38f" }}>Emotional Control - The mindset to be able to manage my emotions (and others too) and not allow my emotions to dictate my actions.</td>
                    </tr>

                    <tr>
                        <th rowSpan="2" style={{ backgroundColor: "#7030A0" }}>Commitment</th>
                        <td style={{ backgroundColor: "#9a6ebd" }}>Goal Orientation - To understand which things need to be done, be able to visualise outcomes and understand why goals and targets are important.</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#9a6ebd" }}>Achievement Orientation - Being minded to make the effort needed to achieve  goals and appreciate the satisfaction gained from doing things well.</td>
                    </tr>

                    <tr>
                        <th rowSpan="2" style={{ backgroundColor: "#4F81BD" }}>Challenge</th>
                        <td style={{ backgroundColor: "#84a7d1" }}>Risk Orientation - To be open to trying new things, meeting new people, going to new places, etc whilst appreciating that this can carry risk.</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#84a7d1" }}>Learning Orientation - To think about what happens to us and around us – Reflecting and learning from all that happens – good and bad.</td>
                    </tr>

                    <tr>
                        <th rowSpan="2" style={{ backgroundColor: "#ed364c" }}>Confidence</th>
                        <td style={{ backgroundColor: "#f69ba5" }}>Confidence in Abilities - My self belief in my skills, knowledge and abilities and be prepared to use them.</td>
                    </tr>
                    <tr>
                        <td style={{ backgroundColor: "#f69ba5" }}>Interpersonal Confidence - To engage with others, to influence others as much as they do you and to be enquiring – comfortable asking questions.</td>
                    </tr>
                </table>

                <p>A much more comprehensive explanation for the framework can be found in “Developing Mental Toughness” (Kogan Page 2021) Strycharczyk, Clough and Perry.</p>

                <h2 style={{ color: "#4f81bd" }}>How do the books work as learning resources?</h2>
                <p>Each book in this series focuses on one or usually two or three of these factors. Each contains material which helps a parent or teacher to reflect on the story and help the child extract the learning from it. Each has all the features you would expect from an engaging story. We are adding an important purpose to the story.</p>

                <iframe
                    id="MTvideo"
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/PiGDZ4vtSEg`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                />

                <h2 style={{ color: "#7030a0" }}>Why adopt this approach?</h2>
                <p>Research shows that Mental Toughness, a personality trait, is a significant influence on Behaviour. Behaviour is how we explain how “we act when things happen”. Because we can see how people act, behaviour can be very visible and we can often assess behaviour with some accuracy.</p>
                <br></br>

                <img src={birdMouseQuestion} id="birdMouseQuestion" alt="Bird and Mouse Asking a Question"></img>

                <p>One of the challenges in developing Metal Toughness qualities is that they are “invisible”. They are in our heads and we can’t see into someone’s head to know what they are thinking.</p>
                <br></br>
                <p>It helps if we have a framework through which we can understand some of this. Even then it is still very difficult to understand what another person is thinking. When working with people aged 10 – 90, AQR have developed a high quality psychometric that helps users to achieve this level of understanding. We can’t do this so easily with very young people.</p>
                <br></br>
                <p>However, we also know, from our research that, generally children are not as mature or as well-developed mentally as adults across all of the 8 factors. It is then reasonable to assume that most children will benefit from a broad range of development activity.</p>
                <br></br>
                <p>The challenge for parents (and teachers) is to identify development activity and to work with children so that this learning occurs.</p>
                <br></br>
                <p>The Resilient Hedgehog books and materials are carefully designed to help this process.</p>
                <br></br>
                <p>The Books are supported by a development resources section on the website for each book that helps a parent or teacher to optimise engagement and learning. Yet other resources include videos, articles, webinars and common interest groups facilitated by AQR.</p>

                <h2 style={{ color: "#007b60" }}>Mental Toughness and Mental Sensitivity</h2>
                <p>Mental Toughness as a concept is a spectrum. It has mental toughness at one end and mental sensitivity at the other end.</p>
                <br></br>
                <p>As expected in many settings the more mentally tough you are the more you will have an advantage in terms of performance, well being and aspirations. The more mentally sensitive often have a more difficult journey through life – setbacks, challenges and upsets can stop them in their tracks and they can take longer to recover from setback.</p>
                <br></br>
                <p>This is true for each of the 8 factors too.  It is possible, even quite normal, to be mentally sensitive on some factors and mentally tough on others.</p>
                <br></br>
                <p>However it is not as “black and white” as this. As important as Mental Toughness is, self-awareness about one’s mental toughness or sensitivity is just as important.</p>
                <br></br>
                <p>Furthermore, a child at this age range is on a journey to adulthood. We can help them on their way through that journey. We can’t take them to the end of the journey. There are other factors at play. But we can make a difference.</p>
                <br></br>

            </article>
        </div>
    )
}
