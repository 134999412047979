import React from 'react';
import "./Books.css";
import beaverCoverArt from "../images/beaverCoverArt.png";
import hedgehogCoverArt from "../images/hedgehogCoverArt.png";
import pelicanCoverArt from "../images/pelicanCoverArt.png";
import slothCoverArt from "../images/slothCoverArt.png";
import ukraineCoverArt from "../images/ukraineCoverArt.png";
import catalogue from "../resources/catalogue.pdf"
import { Link } from 'react-router-dom';

export default function Books() {
    return (
        <div className="books">
            <h1>Books</h1>
            <article>

                    <h2 style={{color: "#ed364c"}}>About the books and how they work..</h2>
<br></br>
                    <p>The cornerstone of the Resilient Hedgehog resources are the children’s books. They fall into two broad groups:</p>
<br></br>               
                    <ul>
                        <li><b>Picture books</b> short stories which are fun to read whilst carrying a significant message. Young readers/listeners can relate to what happens to the characters. These are targeted at ages 3 – 8. At an earlier age they are read by parents. Later they should be capable of being read by the child.</li>
                        <li><b>Stories.</b> These are longer, targeted at older children (8-10 years) - often 3,000 – 5,000 words in length and are themed to explore something important.</li>
                    </ul>
                    <br></br>
                    <p>All are supported with resources and materials that help parents, coaches and teachers optimise learning. As well as picking up themes from the Mental Toughness concept, the stories relate to issues such as:</p>
<br></br>
                    <ul>
                        <li>Peer relationships - making friends </li>
                        <li>Behaviour – classroom, home</li>
                        <li>Keeping promises</li>
                        <li>Bullying and perceived bullying</li>
                        <li>Helping others</li>
                        <li>Teamworking</li>
                        <li>Trust</li>
                        <li>Kindness</li>
                    </ul>

                    <h1 style={{color: "#7030a0"}}>Resilient Creatures <span style={{color: "#d4adf1"}}><span style={{fontSize: "75%"}}>(Ages 3-8)</span></span></h1>

                    <div id="beaverBooks">
                    <img src={beaverCoverArt} id="coverArtBooks" alt="Beaver Cover Art"></img>
                    <div id="bookBio">
                    <h3 style={{color: "#5662aa"}}>Little Beaver, Big Achiever</h3>
                    <p><b>Available Now</b></p>
                    <br></br>
                    <p>Disaster strikes Beaver Town! All the Beavers are upset, except for Little Beaver. She’s determined to rebuild her town, but will she be able to do it?</p>
                    <br></br>
                    <p>Little Beaver, Big Achiever is  a story about what can happen when something goes wrong and how we can put it right. The focus is on learning how to be resilient and positive even when it seems that things go against you.</p>
                    <br></br>
                    <p><i>"This story of the Little Beaver is a great resource to allow children to identify with the Little Beaver, and their own strengths, and how they can overcome challenges. There is real therapeutic value in the story and the scope for additional activities will provide hours of fun and learning about Mental Toughness."</i>
                    <br></br>
                    <b>Maria Lawless, Play Therapist and Primary School Teacher, Ireland</b> </p>
                    <Link to="/resources">Access Resources</Link>
                    </div>
                    </div>

                    <div id="beaverBooks">
                    <img src={hedgehogCoverArt} id="coverArtBooks" alt="How Hedgehog Found His Smile Cover Art"></img>
                    <div id="bookBio">
                    <h3 style={{color:"#fab659"}}>How Hedgehog Found His Smile</h3>
                    <p><b>Available Now</b></p>
                    <br></br>
                    <p>Hedgehog is heartbroken! He has lost his toy crocodile. He searches here, there and everywhere, but crocodile is nowhere to be found. Will Hedgehog ever smile again?</p>
                    <br></br>
                    <p><i>"The children responded well to the questions and I found that even children who are usually less likely to answer were encouraged when reading this story. It was good to have questions throughout the story in order to prompt discussion. The outcomes following completing the story were also successful, with children being able to talk about the story clearly and the themes portrayed."</i>
                    <br></br>
                    <b>Tom Arrandale, Year 2 Teacher</b> </p>
                    <Link to="/resources">Access Resources</Link>
                    </div>
                    </div>

                    <div id="beaverBooks">
                    <img src={pelicanCoverArt} id="coverArtBooks" alt="How Hedgehog Found His Smile Cover Art"></img>
                    <div id="bookBio">
                    <h3 style={{color:"grey"}}>How The Pelican Found Its Name</h3>
                    <p><b>Available Now</b></p>
                    <br></br>
                    <p>The pelicants are bored and listless. Whenever they have an opportunity to do something interesting, they say “no!”. But two of the pelicants decide they will have a go.</p>
                    <br></br>
                    <p><i>"This beautiful series of books introduces characters each with their own challenges and aims to promote Mental Toughness. We particularly like the additional activities which help us to engage with our children and discuss the deeper meaning behind the story."</i>
                    <br></br>
                    <b>Dodleston Church of England PTFA</b> </p>
                    </div>
                    </div>

                    <div id="beaverBooks">
                    <img src={ukraineCoverArt} id="coverArtBooks" alt="How Hedgehog Found His Smile Cover Art"></img>
                    <div id="bookBio">
                    <h3 style={{color:"blue"}}>The Nightingale and the Bear</h3>
                    <p><b>Available Now</b></p>
                    <br></br>
                    <p>The story of the nightingale and the bear describes how we can deal with bullying. It is also about learning why it is important to stand up for yourself, for others and for issues that really matter to you.
                    <br></br>
                    On one level this is a story about bullying. On another, it uses the events of the Russian invasion of Ukraine in 2022 to help parents make sense of events like these with their children.</p>
                    </div>
                    </div>

                    <div id="beaverBooks">
                    <img src={slothCoverArt} id="coverArtBooks" alt="How Hedgehog Found His Smile Cover Art"></img>
                    <div id="bookBio">
                    <h3 style={{color:"green"}}>The Fastest Sloth in the World!</h3>
                    <p><b>Available Now</b></p>
                    <br></br>
                    <p>Sloth wants to be the speediest in the world! His friends think it’s impossible, but Sloth is determined and starts to train. Will Sloth ever be fast?</p>
                    <br></br>
                    <p>The Fastest Sloth in the World is about having self-belief and self-worth, from within and not from others. </p>
                    </div>
                    </div>

                    <h3>Find our full 2022 catalogue <a href={catalogue} target="_blank" rel="noreferrer">here</a></h3>

            </article>
        </div>
    )
}
