import React from 'react';
import { Link } from "react-router-dom";
import Logo from "../images/resilientHedgehog.png";
import "./Navbar.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faLinkedin, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function Navbar() {
    return (
        <div>
            <div className="social">
                <a href="https://www.facebook.com/ResilientHedgehog" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faFacebook} className="icon" />
                </a>
                <a href="https://twitter.com/ResilientHedgeh" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className="icon" />
                </a>
                <a href="https://www.instagram.com/resilient_hedgehog/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="icon" />
                </a>
                <a href="https://www.linkedin.com/company/resilient-hedgehog" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCMyziLPh_E3g3SDs8zVhXwQ/" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faYoutube} className="icon" />
                </a>
            </div>
            <div className="navbar">
                <Link to="/" id="menuLogo"><img src={Logo} alt="Logo" width="100em" /><h1>Resilient Hedgehog</h1></Link>
                <div className="menuItems">
                    <Link to="/" id="menuItem" className="link">Home</Link>
                    <Link to="about" id="menuItem" className="link">About</Link>
                    <Link to="mental-toughness" id="menuItem" className="link">Mental Toughness</Link>
                    <Link to="books" id="menuItem" className="link">Books</Link>
                    <Link to="optimise-learning" id="menuItem" className="link">Optimise Learning</Link>
                    <Link to="resources" id="menuItem" className="link">Resources</Link>
                    <Link to="blog" id="menuItem" className="link">Blog</Link>
                    <Link to="shop" id="menuItem" className="link">Shop</Link>
                    <a href="https://aqrinternational.co.uk/contact-us#form" target="_blank" rel="noreferrer">Contact Us</a>
                </div>
            </div>
        </div>
    )
}