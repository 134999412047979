import React from 'react';
import "./Slideshow.css"


export default function Slideshow() {
    const quotes = [
    { 
      quote: "This story of the Little Beaver is a great resource to allow children to identify with the Little Beaver, and their own strengths, and how they can overcome challenges. There is real therapeutic value in the story and the scope for additional activities will provide hours of fun and learning about Mental Toughness.",
      author: "Maria Lawless, Play Therapist and Primary School Teacher"
    },
    { 
      quote: "This beautiful series of books introduces characters each with their own challenges and aims to promote Mental Toughness. We particularly like the additional activities which help us to engage with our children and discuss the deeper meaning behind the story.",
      author: "Dodleston Church of England PTFA"
    },
    { 
      quote: "As a teacher it was lovely to see the children reacting so positively to the book and really showing aspects of deeper thinking. They themselves were able to relate to both the little beaver and people from the town.",
      author: "Year 1 Teacher, Sudell Primary School"
    },
    {
      quote: "Year 3 really enjoyed the story and it was a great jumping off point for discussions about stereotypes, teamwork and resilience. The questions printed on each page meant it was easy to pick up and just run with as a standalone lesson but there was lots of ideas in the back to develop into more in depth EP lessons.",
      author: "Year 3 Teacher, Austin Friars School"
    }
  ];
    const [quoteData] = React.useState(quotes);
    const [current, setCurrent] = React.useState(0);
    const [quote, getQuote] = React.useState(quoteData[current])
    
    React.useEffect(
      () => getQuote(quoteData[current]), 
      [current, quote, quoteData]
    )
    
    const nextQuote = () => {
      current === quoteData.length-1 ?
        setCurrent(0)
      :
        setCurrent(current + 1)
    }
    
    const prevQuote = () => {
      current === 0 ?
        setCurrent(quoteData.length-1)
      :
        setCurrent(current - 1)
    }
    
    const dotPicksQuote = (e) => setCurrent(Number(e.target.id))
    
    return (
      <section>
        <div className="slideshow-container">
          <Slide quote={quote} />
          <Arrows nextQuote={nextQuote}
                  prevQuote={prevQuote} />
        </div>
        <Dots dotQty={quoteData} 
              current={current}
              dotPicksQuote={dotPicksQuote} />
      </section>  
    )
  }
  
  function Slide({quote}) {
    return (
      <div className="mySlides">
        <q>{quote.quote}</q>
        <p className="author">&mdash;{quote.author}</p>
      </div>
    )
  }
  
  function Arrows({nextQuote, prevQuote}) {
    return (
      <>    
        <p onClick={prevQuote} className="prev" id="prev">&#10094;</p>
        <p onClick={nextQuote} className="next" id="next">&#10095;</p>
      </>
    )
  }
  
  function Dots({dotQty, current, dotPicksQuote}) {
    return (
      <div className="dot-container">
        {
          dotQty.map((dot, i) => {
            return <span id={i} className={current === i ? "dot active" : "dot"}
                          onClick={dotPicksQuote}></span>
          })
        }
      </div>
    )
  }