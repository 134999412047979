import React from 'react';
import "./Shop.css";
import beaverCoverArt from "../images/beaverCoverArt.png";
import hedgehogCoverArt from "../images/hedgehogCoverArt.png";
import pelicanCoverArt from "../images/pelicanCoverArt.png";
import ukraineCoverArt from "../images/ukraineCoverArt.png";
import parentingCoverArt from "../images/parentingCoverArt.png";

export default function Shop() {
    return (
        <div>
            <h1>Shop</h1>
        <div className="shop">
            <div id="bookLink">
            <a href="https://aqrinternational.co.uk/product/little-beaver-big-achiever" target="_blank" rel="noreferrer">
            <img src={beaverCoverArt} id="beaverCoverArtShop" alt="Little Beaver Big Achiever Cover"></img>
            <p>Little Beaver, Big Achiever</p>
            <p>£7.99</p>
            </a>
            </div>

            <div id="bookLink">
            <a href="https://aqrinternational.co.uk/product/how-hedgehog-found-his-smile" target="_blank" rel="noreferrer">
            <img src={hedgehogCoverArt} id="beaverCoverArtShop" alt="How Hedgehog Found His Smile Cover"></img>
            <p>How Hedgehog Found His Smile</p>
            <p>£7.99</p>
            </a>
            </div>

            <div id="bookLink">
            <a href="https://aqrinternational.co.uk/product/how-the-pelican-got-its-name" target="_blank" rel="noreferrer">
            <img src={pelicanCoverArt} id="beaverCoverArtShop" alt="How the Pelican Got is Name Cover"></img>
            <p>How the Pelican Got is Name</p>
            <p>£7.99</p>
            </a>
            </div>

            <div id="bookLink">
            <a href="https://aqrinternational.co.uk/product/the-nightingale-and-the-bear" target="_blank" rel="noreferrer">
            <img src={ukraineCoverArt} id="beaverCoverArtShop" alt="The Nightingale and the Bear"></img>
            <p>The Nightingale and the Bear</p>
            <p>£7.99</p>
            </a>
            </div>

            <div id="bookLink">
            <a href="https://www.amazon.co.uk/Thriving-Mind-developing-resilience-positivity/dp/B0CMV73QJS" target="_blank" rel="noreferrer">
            <img src={parentingCoverArt} id="beaverCoverArtShop" alt="A Thriving Mind"></img>
            <p>A Thriving Mind: A Practical Guide to Developing Resilience and Positivity in Young Children</p>
            <p>£11.99</p>
            </a>
            </div>
        </div>
        </div>
    )
}
