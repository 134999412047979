import React from 'react';
import {Link} from "react-router-dom";
import "./Footer.css";

export default function Footer() {
    return (
        <footer className="footer">
                <div className="footerHeader">
                    <h2>© Resilient Hedgehog <span id="divisionOf">(A Division of AQR International)</span></h2>
                    <p>Unit 8, The Meadows, Dodleston, Chester, CH4 9NG</p>
                </div>
                <div className="footerMenu">
                    <Link to="/" >Home</Link>
                    <Link to="about" >About</Link>
                    <Link to="mental-toughness" >Mental Toughness</Link>
                    <Link to="books" >Books</Link>
                    <Link to="optimise-learning" id="menuItem" className="link">Optimise Learning</Link>
                    <Link to="resources" >Resources</Link>
                    <Link to="blog" >Blog</Link>
                    <Link to="shop" >Shop</Link>
                    <a href="https://aqrinternational.co.uk/terms-and-conditions-2" target="_blank" rel="noreferrer">Terms and Conditions</a>
                    <a href="https://aqrinternational.co.uk/contact-us#form" target="_blank" rel="noreferrer">Contact Us</a>
                </div>
            </footer>
    )
}
